import logo from '../../img/myl-logo.png';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { Col, Row, Layout, Card, Button, Modal } from 'antd';
import { Content } from 'antd/es/layout/layout';
import {useParams} from "react-router-dom";
import SweetAlert2 from 'react-sweetalert2';
import imgLoading from '../../img/loading_dragon.gif';
import saveIcon from '../../img/save_icon.svg';
import Tilt from 'react-parallax-tilt';

const { Meta } = Card;
const Cards = () => {
    const navigate = useNavigate();
    const { edition } = useParams()
    const [cartas,setCartas] = useState([]);
    const [respaldoCartas,setRespaldoCartas] = useState([]);
    const [swalProps, setSwalProps] = useState({});
    const [isModalOpen, setModalOpen] = useState({
        mostrarModal: false,
        image:'',
        name:''
    });
    const [user,setUser] = useState({
        email: "",
        name: "",
        token: "",
        logged: false,
    });
    const [stats,setStats] = useState({
        cantidadDeCartas:0,
        cartasQueTengo: 0,
        ListadoCartasFaltantes: [],
        ListadoCartasQueTengo: [],
        verFaltantes: false
    });


    const [marcarCartas,setMarcarCartas] = useState(false);
    useEffect(() => {
        getCards();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const getCards = async ( ) =>{
        try{
            let user = localStorage.getItem("user-logged");
            if(user){
                user = JSON.parse(user);
                const { _id:ID_USER } = user;
                const { data } = await axios.post('https://myl.backend.jorgealmonacid.com/MyCards/Collection',{ id_edition:edition ,id_user:ID_USER });
                //const { data } = await axios.post('http://localhost:3000/MyCards/Collection',{ id_edition:edition ,id_user:ID_USER });
                if( data.error === false ){
                    const {data: resp } = data;
                    setCartas(resp);
                    setRespaldoCartas(resp);
                    setUser(user);
                    setStats({
                        cantidadDeCartas:resp.length,
                        cartasQueTengo: resp.filter((cartas)=> cartas.collected ).length,
                        ListadoCartasFaltantes: resp.filter((cartas)=> !cartas.collected ),
                        ListadoCartasQueTengo: resp.filter((cartas)=> cartas.collected ),
                    })
                }
            }
        }catch(error){
          console.log(error);
        }
       
    }


    const getCardsAgain = async ( ) =>{
        try{
            setSwalProps({});
            const { _id:ID_USER } = user;
            const { data } = await axios.post('https://myl.backend.jorgealmonacid.com/MyCards/Collection',{ id_edition:edition ,id_user:ID_USER });
            if( data.error === false ){
                const {data: resp } = data;
                setCartas(resp);
                setRespaldoCartas(resp);
                setMarcarCartas(false);
                setStats({
                    cantidadDeCartas:resp.length,
                    cartasQueTengo: resp.filter((cartas)=> cartas.collected ).length,
                    ListadoCartasFaltantes: resp.filter((cartas)=> !cartas.collected ),
                    ListadoCartasQueTengo: resp.filter((cartas)=> cartas.collected ),
                })
            }
          
        }catch(error){
          console.log(error);
        }
       
    }

    const marcarCarta = ({ _id, image_compress, name, image }) =>{
        if(marcarCartas){
            let index = cartas.findIndex(x => x._id === _id );
            let newArray = [...cartas];
            newArray[index].marked =  !newArray[index].marked;
            setCartas(newArray);
        }else{

            setModalOpen({
                mostrarModal: true,
                image_compress,
                name,
                image
            });
        }
       
    }

    const onClickComenzarMarcado = (marcarCartas) =>{
        let newCartas = [...respaldoCartas];
        if(marcarCartas){
            newCartas.forEach(card => {
                if(card.collected){
                    card.marked = true;
                }else{
                    card.marked = false;
                } 
            });

            setCartas(newCartas);
        }else{
            setCartas(newCartas);
        }

        setMarcarCartas(marcarCartas)
    }

   
    const guardarCambios = async () => {
        try{
            let arrayCartasGuardar = cartas.filter((card)=> card.marked === true);
            let array = [];
            arrayCartasGuardar.forEach(card => {
                array.push(card._id);
            });
            const body = {
                id_edition: edition, 
                id_user: user._id, 
                ids_cards: array, 
                quantity: 1 
            }
            setCartas([]);
            const { data } = await axios.post('https://myl.backend.jorgealmonacid.com/MyCards/multiple',body);
            //const { data } = await axios.post('http://localhost:3000/MyCards/multiple',body);
            if( data.error === false ){
                console.log('paso')
                setSwalProps({
                    show: true,
                    title: 'Exito',
                    text: "Se guardaron las cartas",
                })
            }else{
                setSwalProps({
                    show: true,
                    title: 'Error',
                    text: "No se guardaron las cartas. contactar a soporte",
                })
            }
        }catch(error){
            console.log(error)
        }       
    }
    const cerrarSesion = () =>{
        localStorage.clear();
        navigate(`/`);
    }

    let cartasVer = stats.verFaltantes ? stats.ListadoCartasFaltantes : cartas;
    return (
        
        <Layout style={{minHeight: '100vh', padding: 40}}>
            <Content className='container'>
                <Row>
                    <Col span={24} style={{textAlign:'right'}}>
                        <Button 
                            style={{marginTop: 30}}
                            onClick={()=> {cerrarSesion()}}
                        > 
                            Cerrar sesion
                        </Button>
                    </Col>
                    <Col span={24} style={{textAlign:'center'}}>
                        <img src={logo} className={'App-logo-center'} alt="logo" />
                    </Col>
                    <Col span={24} style={{textAlign:'center'}}>
                        <h1>Listado de cartas</h1>
                    </Col>
                    <Col span={24} style={{textAlign:'center'}}>
                        <h1>({stats.cartasQueTengo}/{stats.cantidadDeCartas})</h1>
                    </Col>
                    <Col span={24} style={{textAlign:'center'}}>
                        <Button 
                            disabled={ cartas.length === 0 || stats.verFaltantes === true ? true : false }  
                            onClick={ ()=> onClickComenzarMarcado(!marcarCartas) }
                        >
                            { marcarCartas ? 'Cancelar Cambios': 'Marcar cartas' }
                        </Button>
                        <Button 
                            disabled={ cartas.length === 0 ? true : false }
                            onClick={ ()=> {
                                setStats({ ...stats, verFaltantes: !stats.verFaltantes });
                                onClickComenzarMarcado(false);
                            }}
                        >
                            Ver Cartas faltantes
                        </Button>                        
                    </Col>
                </Row>
                <Row gutter={16} style={{padding: 50}}>
                    {
                        cartas.length === 0 ?
                        <Col
                            span={24}
                            style={{
                                textAlign:'center',
                                marginTop: 100
                            }}
                        >
                            <img src={imgLoading} style={{ width: 300 }} alt="logo" />
                        </Col>
                        
                        :
                        <></>
                    }
                    {cartasVer.map((card,index) => {
                        return(
                            <Col
                                xs={12}
                                sm={12}
                                md={12}
                                lg={6}
                                xl={6}
                                xxl={6}
                                key={`${card.slug}${index}`}
                                style={
                                    marcarCartas ?
                                        card.marked ?
                                        {marginTop:10}
                                        :
                                        {marginTop:10, opacity: 0.5}
                                    :
                                    card.collected ? 
                                        {marginTop:10}
                                        :
                                        {marginTop:10, opacity: 0.5}
                                }
                                onClick={()=> marcarCarta(card)}
                            >
                                <Card
                                    hoverable
                                    style={{ width: '100%' }}
                                    cover={<img alt={card.name} src={card.image_compress} loading="lazy" />}
                                >
                                    <Meta style={{textAlign:'center'}} title={card.name}/>
                                </Card>
                            </Col>
                        )
                    })}
                </Row>
                <SweetAlert2 
                    {...swalProps}
                    onConfirm={()=> getCardsAgain()}
                    didClose={()=> setSwalProps({}) 
                    }
                />
                {
                    marcarCartas ?
                    <Button 
                        style={{ 
                            width:100,
                            height:100, 
                            padding:7, 
                            borderRadius: 50, 
                            backgroundColor:'#009084', 
                            position:'fixed', 
                            bottom: 0, 
                            right: 0,
                            marginRight:30, 
                            marginBottom: 30
                        }}
                        onClick={()=> {guardarCambios()}}
                    > 
                        <img alt='imagen guardar' src={saveIcon} width={40} height={40} /> 
                    </Button>
                    :
                    <></>
                }
               
            </Content>
            <Modal 
                title={()=>{
                    return<div style={{ width: '100%', textAlign:'center',marginTop: 50 }}>
                        <h1 style={{ backgroundColor:'black',}}>hola</h1>
                    </div>
                }}
                width={800} 
                open={isModalOpen.mostrarModal} 
                onOk={()=> setModalOpen({...isModalOpen,mostrarModal: false,image:''})} 
                onCancel={()=> setModalOpen({...isModalOpen,mostrarModal: false,image:''})}
                footer={()=>{
                    return <div style={{ width: '100%', textAlign:'center',marginTop: 50 }}>
                    <Button 
                        style={{ 
                          width: 100,
                        }}
                        onClick={()=> setModalOpen({...isModalOpen,mostrarModal: false,image:''})}
                    > 
                        Cerrar
                    </Button>
                    </div>
                }}
            >
              <Row>
                <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    xxl={24}
                    style={{
                        textAlign:'center',
                        padding: 50
                    }}
                >
                     <Tilt>
                        { isModalOpen.image ?
                            <img alt={isModalOpen.name} src={ isModalOpen.image } style={{width:'100%', maxWidth: 500}} />
                        :
                            <></>
                        }
                        
                    </Tilt>
                </Col>
              </Row>
            </Modal>
        </Layout>
    )
}
export default Cards;