import './Home.css';
import logo from './img/myl-logo.png';
import working from './img//working.webp';
import axios from 'axios';
import { GoogleLogin } from '@react-oauth/google';
import { Col, Row, Layout, Button } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from 'react';

function App() {
  const navigate = useNavigate();
  const [loading,setLoading] = useState(false);
  const [user,setUser] = useState({
    email: "",
    name: "",
    token: "",
    logged: false,
  });
  useEffect(() => {
    const loadFirstData = async () =>{
      let newUser = localStorage.getItem('user-logged');
      if(newUser){
        redirectToEditions();
      }
    }
    loadFirstData();
    // eslint-disable-next-line
  }, [])
  
  const logIn = async ( credentialResponse ) =>{
    try{
      setLoading(true);
      const { data } = await axios.post('https://myl.backend.jorgealmonacid.com/User',credentialResponse);
      
      if( data.error === false ){
        const {data: resp } = data;
        let newUser = resp[0];
        newUser.logged = true;
        localStorage.setItem("user-logged",JSON.stringify(newUser))
        setUser(newUser);
        redirectToEditions();
      }
      setLoading(false);
    }catch(error){
      setLoading(false);
      console.log(error);
    }
   
  } 

  const closeSession = () =>{
    localStorage.clear();
    setUser({
      email: "",
      name: "",
      token: "",
      logged: false,
    })
  }

  const redirectToEditions = ( ) =>{
    navigate(`/Editions`);
}
  return (
    <Layout style={{height:'100vh', padding: 40}}>
      <Content>
        <Row>
          <Col span={24} style={{textAlign:'center'}}>
            {user.logged ?
              <h1>{ user.name }, Bienvenido a</h1>
              :
              <h1>Bienvenidos a</h1>
            }
            <img src={logo} className={loading ? 'App-logo' :'App-logo-center'} alt="logo" />
          </Col>
          <Col span={24} style={{justifyContent:'center',flexDirection:'column',display:'flex',alignItems:'center'}}>
            {user.logged ? 
              <>
                <Button onClick={ closeSession }>Cerrar sesion</Button>
                <img src={working} className={'App-logo-working'} alt="logo" />
                <h3>Estamos trabajando en las otras funcionalidades</h3>
              </>
              :
              <GoogleLogin
                onSuccess={ logIn }
                onError={() => {
                  console.log('Login Failed');
                }}
              />
            }
           
          </Col>
        </Row>
      </Content>
    </Layout>
    // <div className="App">
    //   <header className="App-header">
    //    
      
    //   </header>
  
    // </div>
  );
}

export default App;
