import React from 'react';
import ReactDOM from 'react-dom/client';
import { GoogleOAuthProvider } from '@react-oauth/google';
import './index.css';
import Home from './Home';
import Editions from './views/Editions/Editions';
import Cards from './views/Cards/Cards';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <GoogleOAuthProvider clientId={"320942731978-qgb2uq3o65qabe7bd6c2kt6pn5l8bdfm.apps.googleusercontent.com"}>
    <React.StrictMode>
      {/* <App /> */}
      <BrowserRouter>
        <Routes>
          {/* <Route path="/" element={<Layout />}> */}
            <Route index element={<Home />} />
            <Route path="/Editions" element={<Editions />} />
            <Route path="/Cards/:edition"  element={<Cards />} />
          {/* </Route> */}
        </Routes>
    </BrowserRouter>
    </React.StrictMode>
  </GoogleOAuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
