import logo from '../../img/myl-logo.png';
// import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Col, Row, Layout, Card, Button } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useNavigate } from 'react-router-dom';
import { reqRespApi } from '../../api/reqRes';


const { Meta } = Card;
const Editions = () => {
    const navigate = useNavigate();
    const [editions,setEditions] = useState([]);
    useEffect(() => {
        getEditions();
    }, []);

    const getEditions = async ( ) =>{
        try{
          const { data } = await reqRespApi.get('/Edition');
          
          if( data.error === false ){
            const {data: resp } = data;
            setEditions(resp);
          }
        }catch(error){
          console.log(error);
        }
       
    } 

    const redirectToCards = ( ID_CARD ) =>{
        navigate(`/Cards/${ ID_CARD }`);
    }

    const cerrarSesion = () =>{
        localStorage.clear();
        navigate(`/`);
    }
    
    return (
        <Layout style={{minHeight: '100vh',padding:40}}>
            <Content className='container'>
                <Row>
                    <Col span={24} style={{textAlign:'right'}}>
                        <Button 
                            style={{marginTop: 30}}
                            onClick={()=> {cerrarSesion()}}
                        > 
                            Cerrar sesion
                        </Button>
                    </Col>
                    <Col span={24} style={{textAlign:'center'}}>
                        <img src={logo} className={'App-logo-center'} alt="logo" />
                    </Col>
                    <Col span={24} style={{textAlign:'center'}}>
                        <h1>Ediciones</h1>
                    </Col>
                </Row>
                <Row>
                    {editions.map(( edition,index ) => {
                        return(
                            <Col
                                xs={24}
                                sm={24}
                                md={24}
                                lg={8}
                                xl={8}
                                xxl={8}
                                key={`${edition.slug}${index}`}
                                onClick={ () => redirectToCards(edition._id) }
                                style={{ padding: 20 }}
                            >
                                <Card
                                    hoverable
                                    style={{ width: '100%',textAlign:'center', height:250, display: 'flex',justifyContent: 'center',alignItems: 'center'}}
                                    cover={<img alt={edition.name} src={edition.image} loading="lazy" style={{ width:'200px',  display:'block',margin:'auto' }}/>}
                                >
                                    <Meta title={edition.name} style={{textAlign:'center'}}/>
                                </Card>
                            </Col>
                        )
                    })}
                </Row>
            </Content>
        </Layout>
    )
}
export default Editions;